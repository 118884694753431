import { flatten, get, keyBy, uniq } from 'lodash';

import { ORGANIZATION_TYPE_IDS } from 'constants/index';
import { getProductionWork, getWorkRoleCanonicalName } from 'utils/common';
import { PRODUCTION_ADDED_IDENTIFIERS_IDS, TP } from 'constants/consts';
import { useTranslation } from 'src/i18n';
import { getComposerNamesFromCreators } from 'utils/composer';

export const getWorkName = production =>
  production?.name || getProductionWork(production)?.original_name || getProductionWork(production)?.name;

/**
 * Get production name formatted for SEO
 *
 * @param {Object} production
 * @returns {string}
 */
export const getSeoProductionName = production => {
  const productionName = get(production, 'name');
  const work = getProductionWork(production);
  const originalName = work?.original_name;

  const workName = work?.name;
  let translation;
  if (workName && originalName !== workName) {
    translation = `(${workName})`;
  }
  let name = [originalName, translation].filter(Boolean).join(' ');
  if (productionName) {
    name = [productionName, name].filter(Boolean).join(': ');
  }
  return name;
};

/**
 * Receive an array of productions and dates
 *
 * @param productions
 * @param dates
 * @returns {(*&{dates: *})[]|Array}
 */
export function mapProductionsDataToPerformedDates(productions, dates = []) {
  if (!Array.isArray(dates) || !Array.isArray(productions)) {
    return productions;
  }

  const selectedDates = keyBy(dates, 'productionId');
  return productions.map(p => {
    const allSelectedDates = uniq(flatten(Object.values(get(selectedDates, `${p?.id}.artistDates`, []))));
    const datesWithPerformedDate = get(p, 'dates', []).map(day => ({
      ...day,
      performed: allSelectedDates?.find(selDate => selDate?.startDate === day?.startDate)?.startDate ?? false,
    }));
    return { ...p, dates: datesWithPerformedDate };
  });
}

export function isProducedByCompany(production) {
  if (!production?.forEntity) {
    return production?.companies?.length > 0;
  }
  return production?.forEntity === PRODUCTION_ADDED_IDENTIFIERS_IDS.COMPANY;
}

export function usePartnerSortedData(data) {
  if (!Array.isArray(data)) {
    return [];
  }

  const base = data.filter(item => !item?.isPartner);
  const partners = data.filter(item => item?.isPartner);
  return [...base, ...partners];
}

/**
 * @TODO: Remove this
 * We need to display companies first if production was added for company
 *
 * @param production
 * @returns {*[]}
 */

// Filter contributions based on organization type and return festivals and companies separately
function filterContributionsByOrganizationType(contributions) {
  const unsortedFestivals = contributions?.filter(contribution => {
    const organizationTypeId = contribution?.organization?.organizationType?.id;
    return organizationTypeId === ORGANIZATION_TYPE_IDS.FESTIVAL;
  });

  const unsortedCompanies = contributions?.filter(contribution => {
    const organizationTypeId = contribution?.organization?.organizationType?.id;
    return organizationTypeId === ORGANIZATION_TYPE_IDS.COMPANY;
  });

  return { unsortedFestivals, unsortedCompanies };
}

export function useCompaniesAndFestivalsData(production, includeProducerRef = true) {
  const { unsortedFestivals, unsortedCompanies } = filterContributionsByOrganizationType(production?.contributions);
  const festivals = usePartnerSortedData(unsortedFestivals);
  const companies = usePartnerSortedData(unsortedCompanies);
  const festivalsWithType = festivals.map(festival => ({
    ...festival,
    type: PRODUCTION_ADDED_IDENTIFIERS_IDS.FESTIVAL,
  }));
  const companiesWithType = companies.map(company => ({
    ...company,
    type: PRODUCTION_ADDED_IDENTIFIERS_IDS.COMPANY,
  }));

  if (!production?.producerReference || !includeProducerRef) {
    return [...companiesWithType, ...festivalsWithType];
  }

  return [...companiesWithType, ...festivalsWithType, { id: 'Other', name: production?.producerReference }];
}

/**
 * Get production media title
 *
 * @param production
 * @returns {string}
 */
export function useProductionMediaTitle(production) {
  const { t } = useTranslation();

  if (!production) {
    return '';
  }

  const productionName = production?.name;
  const work = production?.productionWorks ? production?.productionWorks?.[0] : null;
  const productionWork = {
    work,
  };

  const workName = getWorkRoleCanonicalName(productionWork?.work);
  const composerName = getComposerNamesFromCreators(work?.work?.creators);
  const worksCount = production?.productionWorks?.length;
  let fullTitle = [productionName, workName].filter(Boolean).join(': ');

  if (composerName) {
    fullTitle += ` ${composerName}`;
  }

  if (worksCount > 1) {
    const moreLabel = `(+${worksCount - 1} ${t(`${TP}.MORE`)})`;

    fullTitle += ` ${moreLabel}`;
  }

  return fullTitle;
}
