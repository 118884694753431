import React from 'react';

import AutoCompleteDropdown from 'components/uiLibrary/FormInputs/BaseComponents/AutoCompleteDropdown';

import orgQueries from 'containers/Organizations/queries';

import { useAppliedOrgTypeFilter } from 'utils/globals/ssrQueries/hooks';

import { TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

const OrgTypeDropdown = ({ className, value, onChange }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  const organizationType = useAppliedOrgTypeFilter(value);

  return (
    <AutoCompleteDropdown
      className={className}
      label={t(`${TP}.FN_ORG_TYPE`)}
      value={organizationType}
      onChange={onChange}
      searchQuery={orgQueries.getOrganizationTypes}
    />
  );
};

export default OrgTypeDropdown;
