import React, { useCallback, useMemo } from 'react';

import useTracking from 'components/Globals/Analytics';
import CityDropdown from 'components/Globals/FormFields/Display/CityDropdown';
import CountryDropdown from 'components/Globals/FormFields/Display/CountryDropdown';
import OrgTypeDropdown from 'components/Globals/FormFields/Display/OrgTypeDropdown';

import { ORGANIZATION_TYPE_IDS, AGGREGATION_ON } from 'constants/index';
import { FILTER_SLUG_TYPE, FILTER_TYPES } from 'constants/filters';

import usePageContext from 'utils/hooks/usePageContext';

const OrgListingFilterDropdown = ({ value, filterType, clearFilterTypes, onChange, className, trackingData = {} }) => {
  const track = useTracking();
  const { filters, organizationType } = usePageContext();
  const clearFilterKeys = useMemo(() => clearFilterTypes?.map(type => FILTER_SLUG_TYPE[type]) || [], [
    clearFilterTypes,
  ]);

  const onChangeHandler = useCallback(
    (e, selectedValue) => {
      const key = FILTER_SLUG_TYPE[filterType];
      let allKeysToClear = [...clearFilterKeys, key];
      const filterUpdates = {};
      if (selectedValue) {
        filterUpdates[key] = [selectedValue];
      }

      if (filterType === FILTER_TYPES.CITY && selectedValue) {
        const countryForSelectedCity = selectedValue?.country;

        if (countryForSelectedCity) {
          const countryKey = FILTER_SLUG_TYPE[FILTER_TYPES.COUNTRY];
          allKeysToClear.push(countryKey);

          filterUpdates[countryKey] = countryForSelectedCity ? [countryForSelectedCity] : null;
        }
      }

      if (filterType === FILTER_TYPES.ORGANIZATION_TYPE && !selectedValue) {
        allKeysToClear = [key];
      }

      track.click({
        ...trackingData,
        meta: {
          id: selectedValue?.slug,
        },
      });

      onChange({
        filterUpdates,
        clearFilterKeys: allKeysToClear,
      });
    },
    [filterType, clearFilterKeys, track, trackingData, onChange],
  );

  if (filterType === FILTER_TYPES.COUNTRY) {
    return (
      <CountryDropdown
        className={className}
        value={value}
        aggregationOn={AGGREGATION_ON.ORGANIZATIONS}
        aggregationFilters={{
          organization_type_id: organizationType?.id,
          ...(organizationType?.id !== ORGANIZATION_TYPE_IDS.COMPETITION && { has_productions: 'yes' }),
        }}
        onChange={onChangeHandler}
      />
    );
  }

  if (filterType === FILTER_TYPES.CITY) {
    const selectedCountry = filters?.[FILTER_SLUG_TYPE[FILTER_TYPES.COUNTRY]]?.[0];

    return (
      <CityDropdown
        className={className}
        value={value}
        aggregationOn={AGGREGATION_ON.ORGANIZATIONS}
        aggregationFilters={{
          organization_type_id: organizationType?.id,
          country_id: selectedCountry?.id,
          ...(organizationType?.id !== ORGANIZATION_TYPE_IDS.COMPETITION && { has_productions: 'yes' }),
        }}
        onChange={onChangeHandler}
      />
    );
  }

  if (filterType === FILTER_TYPES.ORGANIZATION_TYPE) {
    return <OrgTypeDropdown className={className} value={value} onChange={onChangeHandler} />;
  }

  return null;
};

export default OrgListingFilterDropdown;
