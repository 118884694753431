/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useMemo, useCallback } from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import EntityName from 'components/Globals/EntityName';
import LinkButton from 'components/uiLibrary/LinkButton';
import WorkTypeTag from 'components/Work/Display/WorkTypeTag';
import SeparatorList, { SEPARATOR_TYPES } from 'components/uiLibrary/SeparatorList';
import GroupedDateList from 'components/uiLibrary/GroupedDateList';

import ImpressionTracker from 'utils/components/impressionTracker';
import { createDate, getFormattedDateRange } from 'utils/date';
import {
  getEntityAndTypeFromContributor,
  getProductionCredits,
  getProductionTags,
  getProductionTitles,
} from 'utils/productions';
import usePageContext from 'utils/hooks/usePageContext';

import { TP, ENTITY_TYPE, PERFORMANCE_DATE_MODE_TYPES, DATE_FORMATS, TAG_TYPES } from 'constants/index';

import { useTranslation } from 'src/i18n';
import useTracking from 'components/Globals/Analytics';
import { getProgramLabel } from 'utils/common';

import classes from './ProductionSlug.module.scss';

const MediaCarousel = dynamic(() => import('../MediaCarousel'));

export const ProductionTag = ({ tag, icon, className }) => (
  <div className={classnames(classes.productionTag, className)}>
    {icon && <SpriteIcon className={classes.productionTag_icon} icon={icon} />}
    {tag}
  </div>
);

export const DateTimeTag = ({
  startDate,
  endDate,
  time,
  location,
  linkProps,
  showChevronIcon,
  skipYear = false,
  hideLinks,
  trackingData,
  venue,
  styles = {},
  onClick,
  preventDefault = true,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate } = usePageContext();
  const { rangeStart, rangeEnd, separator, weekdayStart, fromISO, toISO } = useMemo(
    () => getFormattedDateRange({ from: startDate, to: endDate || startDate, asParts: true }),
    [startDate, endDate],
  );

  if (rangeStart) {
    return (
      <LinkButton
        styles={{ root: classnames(classes.performance, styles.root) }}
        variant="native"
        {...linkProps}
        stopPropagation={!hideLinks && !!linkProps && !showChevronIcon}
        preventDefault={(hideLinks || showChevronIcon) && preventDefault}
        isLink
        onClick={onClick}
        trackingData={trackingData}
      >
        <ImpressionTracker data={trackingData} className={classes.performanceNode}>
          {skipYear ? (
            <Typography variant="p" className={classnames(classes.dateTag, styles.dateTag)}>
              <time dateTime={fromISO}>{createDate(fromISO)?.format(DATE_FORMATS.DAY_MONTH)}</time>
            </Typography>
          ) : (
            <Typography variant="p" className={classnames(classes.dateTag, styles.dateTag)}>
              <time dateTime={fromISO}>{rangeStart}</time>
              {separator}
              {rangeEnd && <time dateTime={toISO}>{rangeEnd}</time>}
            </Typography>
          )}
        </ImpressionTracker>
        {time && (
          <Typography variant="p" className={classnames(classes.timeTag, styles.timeTag)}>
            <Typography
              variant="span"
              weight="regular"
              color="secondary"
              align="right"
              className={classnames(styles.timeTagDay)}
            >
              {weekdayStart}
            </Typography>
            <SpriteIcon className={classnames(classes.clockIcon, styles.clockIcon)} icon="schedule" size={12} />
            <Typography variant="span" weight="medium" color="secondary" className={classnames(styles.timeTagTime)}>
              {time}
            </Typography>
          </Typography>
        )}
        {(location || venue?.id) && (
          <Typography
            variant="span"
            weight="regular"
            color="secondary"
            truncate
            className={classnames(classes.location, styles.location)}
          >
            <SpriteIcon
              icon="location_on_outline"
              size={12}
              className={classnames(classes.locationIcon, styles.locationIcon)}
            />
            <ImpressionTracker
              as="span"
              data={{
                ...trackingData,
                entityId: venue?.id,
                entityName: venue?.name || location,
                entityType: ENTITY_TYPE.VENUE,
              }}
            >
              {location}
              {venue?.id ? (
                <>
                  {' '}
                  (
                  <EntityName entity={venue} entityType={ENTITY_TYPE.ORGANIZATION} isRaw />)
                </>
              ) : (
                <> ({t(`${TP}.FN_VENUE_NOT_ANNOUNCED`)})</>
              )}
            </ImpressionTracker>
          </Typography>
        )}

        {showChevronIcon && (
          <SpriteIcon
            icon="chevron_right"
            className={classnames(classes.performance__icon, styles.performanceIcon)}
            onClick={event => {
              event.stopPropagation();
              navigate.to(linkProps);
            }}
          />
        )}
      </LinkButton>
    );
  }

  return null;
};

const PerformanceOtherDates = ({ dates, excludeDates, startYear, filterFutureDates, showCount, onClick }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  if (!dates?.length || dates?.length === excludeDates?.length) {
    return null;
  }

  return (
    <div className={classnames(classes.performance_indent, classes.performance__allDates)}>
      <GroupedDateList
        dates={dates}
        excludeDates={excludeDates}
        startYear={startYear}
        getMoreLabel={count => t(`${TP}.FN_MORE_DATES_FOR_PRODUCTION`, { count })}
        filterFutureDates={filterFutureDates}
        showCount={showCount}
      />
      <SpriteIcon icon="chevron_right" className={classes.performance__allDates_icon} onClick={onClick} />
    </div>
  );
};

const PerformanceDatesInfo = ({ data, linkProps, onClick, openQuickPreview, hideLinks, trackingData }) => {
  const getProductionDates = useCallback(performances => performances?.map(({ startDate }) => startDate), []);
  const getExcludedDates = useCallback(dates => dates?.map(({ date }) => date), []);
  const getLastShownYear = useCallback(dates => {
    const lastDate = dates?.[dates?.length - 1]?.date;

    return createDate(lastDate).format('YYYY');
  }, []);

  const handlePerformanceClick = (e, { productionId }) => {
    e.stopPropagation();
    openQuickPreview({ productionId });
  };

  if (data?.dates) {
    return (
      <>
        {data?.dates?.map(({ id, date, time, location, title, venue }) => (
          <DateTimeTag
            key={id}
            linkProps={{
              ...linkProps?.getSubPath({
                path: createDate(date)
                  .locale('en')
                  .format(DATE_FORMATS.URL_FULL_DATE),
              }),
              title,
            }}
            hideLinks={hideLinks}
            startDate={date}
            endDate={date}
            time={time}
            location={location}
            venue={venue}
            onClick={e => handlePerformanceClick(e, { productionId: data.id })}
            skipYear
            showChevronIcon
            trackingData={{
              ...trackingData,
              entityId: data.id, // productionId
              entityName: data.name,
              entityType: ENTITY_TYPE.PRODUCTION,
              meta: {
                ...trackingData?.meta,
                performanceId: id,
                performanceDate: date,
              },
            }}
          />
        ))}
        <PerformanceOtherDates
          dates={data?.upcomingDates}
          excludeDates={getExcludedDates(data?.dates)}
          startYear={getLastShownYear(data?.dates)}
          onClick={onClick}
          filterFutureDates
          showCount
        />
      </>
    );
  }

  return (
    <>
      <DateTimeTag
        linkProps={linkProps}
        startDate={data?.minDate}
        endDate={data?.maxDate}
        trackingData={{
          ...trackingData,
          entityId: data?.id,
          entityName: data?.name,
          entityType: ENTITY_TYPE.PRODUCTION,
          meta: {
            minDate: data?.minDate,
            maxDate: data?.maxDate,
          },
        }}
      />
      <PerformanceOtherDates dates={getProductionDates(data?.performances)} onClick={onClick} />
    </>
  );
};

const RedMaskIcon = ({ entity, entityType, data }) => {
  const showRedMask = data?.contributions?.find(contributor => {
    const { entity: contributorEntity } = getEntityAndTypeFromContributor(contributor);
    return contributorEntity?.id === entity?.id;
  })?.isRedMasked;

  const { show, icon } = useMemo(() => {
    if ([ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType)) {
      return {
        show: true,
        icon: showRedMask ? 'red_mask' : 'grey_mask',
      };
    }

    return {
      show: false,
      icon: null,
    };
  }, [entityType, showRedMask]);

  if (show) {
    return <SpriteIcon className={classes.redMaskIcon} icon={icon} />;
  }

  return null;
};

const ContentWithSeason = ({ children, upcoming = false, isPerformanceView = false, startDate, endDate, onClick }) => {
  const { startYear, endYear, fromISOString, toISOString } = useMemo(() => {
    if (upcoming) {
      return {};
    }

    const { fromISO, toISO } = getFormattedDateRange({
      from: startDate,
      to: endDate || startDate,
      asParts: true,
    });

    const startYearString = createDate(fromISO).format('YYYY');
    const endYearString = toISO ? createDate(toISO).format('YYYY') : null;

    return {
      fromISOString: fromISO,
      toISOString: toISO,
      startYear: startYearString,
      endYear: startYearString !== endYearString ? endYearString : null,
    };
  }, [upcoming, startDate, endDate]);

  if (isPerformanceView) {
    return <div className={classes.contentWithSeason__content}>{children}</div>;
  }

  return (
    <div className={classes.contentWithSeason}>
      <div className={classes.contentWithSeason__content}>{children}</div>
      <Typography className={classes.contentWithSeason__info} variant="p" color="secondary">
        <time dateTime={fromISOString}>{startYear}</time>
        {endYear && ' - '}
        {endYear && <time dateTime={toISOString}>{endYear}</time>}
        <SpriteIcon icon="chevron_right" className={classes.contentWithSeason__info_icon} onClick={onClick} />
      </Typography>
    </div>
  );
};

export const ProductionTags = ({
  data,
  styles = {},
  color = 'secondary',
  isPerformanceView = false,
  enableRentalsTag = true,
  enableWatchOptionsTag = true,
  enableTicketsTag = true,
  enableProductionWorksTag = true,
  enableAvailabilityTags = true,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { hasTickets, hasWatchOptions, hasRentals, isCancelled, isArchived } = useMemo(() => {
    let canPurchaseTickets = false;
    const productionMaxDate = createDate(data?.maxDate);

    if (!data?.isCancelled) {
      const today = createDate();

      if (isPerformanceView) {
        canPurchaseTickets = data?.dates?.some(({ date, boxOfficeWebsite }) => {
          if (!boxOfficeWebsite) {
            return false;
          }

          const performanceDate = createDate(date);

          return performanceDate.isAfter(today, 'day') || performanceDate.isSame(today, 'day');
        });
      } else {
        const lastPerformanceDate = createDate(data?.maxDate);
        canPurchaseTickets =
          !!data?.tickets?.some(({ boxOfficeWebsite }) => !!boxOfficeWebsite) &&
          (lastPerformanceDate.isAfter(today, 'day') || lastPerformanceDate.isSame(today, 'day'));
      }
    }

    return {
      hasTickets:
        enableTicketsTag &&
        canPurchaseTickets &&
        (createDate().isSame(productionMaxDate, 'day') || productionMaxDate.isAfter(createDate(), 'day')),
      hasRentals: enableRentalsTag && data?.stats?.rentals?.exists,
      hasWatchOptions:
        enableWatchOptionsTag &&
        data?.performances?.some(performance =>
          [PERFORMANCE_DATE_MODE_TYPES.FULL_VIDEO, PERFORMANCE_DATE_MODE_TYPES.LIVESTREAM].includes(performance?.mode),
        ),
      isCancelled: data?.isCancelled,
      isArchived: data?.isArchived,
    };
  }, [data, isPerformanceView]);

  const { workTypeTag, otherTags } = useMemo(() => {
    const isRevival = data?.isRevival;
    let commonTags = [];

    if (isRevival) {
      commonTags = [
        ...commonTags,
        {
          type: TAG_TYPES.PRODUCTION,
          label: t(`${TP}.FN_REVIVAL`),
        },
      ];
    }

    if (enableProductionWorksTag && (data?.productionWorks?.length > 1 || data?.isProgramNotAnnounced)) {
      commonTags = [
        ...commonTags,
        {
          type: TAG_TYPES.PRODUCTION,
          label: (
            <Typography
              variant="span"
              className={classnames(classes.tag, {
                [classes.isBlur]: data?.id === -1,
              })}
              color={color}
              size={12}
            >
              {data?.isProgramNotAnnounced
                ? t(getProgramLabel(data, data?.productionWorks?.length))
                : t(`${TP}.FN_PROGRAM_COUNT_WORKS`, { count: data?.productionWorks?.length })}
              <SpriteIcon size={12} icon="chevron_right" />
            </Typography>
          ),
          skipWrapper: true,
        },
      ];
    }

    const tags = getProductionTags(
      {
        ...data,
        performances: data?.performances || data?.dates,
      },
      [TAG_TYPES.PRODUCTION, TAG_TYPES.FESTIVAL, TAG_TYPES.DATE],
    );

    let stagingTypeTag = [];
    const stagingType =
      data?.productionWorkTypes?.[0]?.stagingType ||
      data?.productionWorks?.[0]?.productionWorkToWorkTypes?.[0]?.stagingType;

    if (stagingType) {
      stagingTypeTag = [
        {
          label: stagingType?.name,
          className: styles.stagingTypeTag,
        },
      ];
    }

    return {
      otherTags: [...stagingTypeTag, ...tags, ...commonTags]?.map(tag => {
        if (tag.skipWrapper) {
          return tag.label;
        }
        return (
          <Typography
            color={color}
            className={classnames(classes.tag, tag.className, { [classes.isBlur]: data?.id === -1 })}
            size={12}
          >
            {t(tag?.label)}
          </Typography>
        );
      }),
      workTypeTag:
        data?.productionWorkTypes?.[0]?.workType ||
        data?.productionWorks?.[0]?.productionWorkToWorkTypes?.[0]?.workType ||
        data?.productionWorks?.[0]?.work?.workType,
    };
  }, [t, data]);
  return (
    <div className={classnames(classes.tagList, styles.root)}>
      <div className={classes.productionTags}>
        <WorkTypeTag tag={workTypeTag} className={styles.workTag} />
        <SeparatorList
          styles={{ item: classnames(classes.separatorTag, styles.separatorTag) }}
          data={otherTags}
          separator={SEPARATOR_TYPES.BULLET}
        />
      </div>
      {enableAvailabilityTags ? (
        <div className={classes.availabilityTags}>
          {isCancelled && <ProductionTag tag={t(`${TP}.PERFORMANCE_CANCELLED`)} className={classes.cancelledTag} />}
          {!isCancelled && isArchived && <ProductionTag tag={t(`${TP}.ARCHIVED`)} />}
          {hasTickets && <ProductionTag tag={t(`${TP}.FN_TICKETS`)} icon="tickets" />}
          {hasWatchOptions && <ProductionTag tag={t(`${TP}.FN_WATCH_OPTIONS`)} icon="live_tv" />}
          {hasRentals && <ProductionTag tag={t(`${TP}.FN_RENTAL_AVAILABLE`)} />}
        </div>
      ) : null}
    </div>
  );
};

const ProductionSlug = ({
  entity,
  entityType,
  data,
  upcoming = false,
  onClick,
  className,
  getLinkProps,
  withMedia = false,
  hideLinks = false,
  trackingData,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate } = usePageContext();
  const track = useTracking();
  const isPerformanceView = !!data?.dates;
  const isCancelled = data?.isCancelled;
  const isArchived = data?.isArchived;

  const { producers, directors, conductors, entityRoles } = getProductionCredits(data, {
    entityId: entity?.id,
    entityType,
  });
  const roles =
    entityRoles?.length > 0
      ? `${t(entityRoles?.[0])} ${
          entityRoles?.length > 1 ? `${t(`${TP}.FN_ROLES_WITH_COUNT`, { count: entityRoles?.length - 1 })} ` : ''
        }`
      : '';
  const isCover = data?.contributions?.find(act => act?.profile?.id === entity?.id)?.isCover;

  const producerNames = useMemo(
    () =>
      producers?.data?.map(producer => (
        <Typography
          key={producer?.entity?.id}
          variant="span"
          color="secondary"
          strikethrough={isCancelled}
          blur={isArchived}
        >
          <EntityName entity={producer?.entity} entityType={producer?.entityType} isRaw trackingData={trackingData} />
        </Typography>
      )),
    [producers, isCancelled, isArchived],
  );

  const directorNames = useMemo(
    () =>
      directors?.data?.map(director => (
        <Typography
          key={director?.entity?.id}
          variant="span"
          color="secondary"
          strikethrough={isCancelled}
          blur={isArchived}
        >
          <EntityName entity={director?.entity} entityType={director?.entityType} isRaw trackingData={trackingData} />
        </Typography>
      )),
    [directors, isCancelled, isArchived],
  );

  const conductorNames = useMemo(
    () =>
      conductors?.data?.map(conductor => (
        <Typography
          key={conductor?.entity?.id}
          variant="span"
          color="secondary"
          strikethrough={isCancelled}
          blur={isArchived}
        >
          <EntityName entity={conductor?.entity} entityType={conductor?.entityType} isRaw trackingData={trackingData} />
        </Typography>
      )),
    [conductors, isCancelled, isArchived],
  );

  const onClickHandler = useCallback(() => {
    if (onClick) {
      const { dates } = data;
      const performanceId = dates?.length === 1 ? dates?.[0]?.id : null;
      onClick({ productionId: data?.id, performanceId });
    }
    track.click({
      ...trackingData,
      entityId: data.id,
      entityName: data.name,
      entityType: ENTITY_TYPE.PRODUCTION,
    });
  }, [onClick, data]);

  const linkProps = useMemo(() => {
    if (getLinkProps) {
      return getLinkProps({
        entityType: ENTITY_TYPE.PRODUCTION,
        entity: data,
      });
    }

    return navigate.getLinkProps({
      entityType: ENTITY_TYPE.PRODUCTION,
      entity: data,
    });
  }, [navigate, data, getLinkProps]);

  const navigateToLinkProps = useCallback(
    e => {
      if (!hideLinks) {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }

        if (linkProps) {
          navigate.to(linkProps);
        }
      }
    },
    [hideLinks, navigate, linkProps],
  );

  const titles = useMemo(() => getProductionTitles(data), [data]);

  return (
    <div
      className={classnames(
        classes.root,
        {
          [classes.archived]: isArchived,
          [classes.cancelled]: isCancelled,
          [classes.performanceView]: isPerformanceView,
          [classes.withMedia]: withMedia,
          [classes.performancesTodayView]: !entityType && !entity?.id,
        },
        className,
      )}
      onClick={onClickHandler}
    >
      <ImpressionTracker
        data={{
          ...trackingData,
          entityId: data.id,
          entityName: data.name,
          entityType: ENTITY_TYPE.PRODUCTION,
        }}
      >
        {withMedia && (
          <div className={classes.media}>
            <MediaCarousel data={data?.media} title={titles?.[0]?.title} />
          </div>
        )}
        <ProductionTags
          data={data}
          isPerformanceView={isPerformanceView}
          styles={{ workTag: classes.workTag }}
          entity={entity}
        />
        <ContentWithSeason
          upcoming={upcoming}
          isPerformanceView={isPerformanceView}
          startDate={data?.minDate}
          endDate={data?.maxDate}
          onClick={navigateToLinkProps}
        >
          <>
            <LinkButton
              variant="native"
              {...linkProps}
              isLink
              preventDefault
              styles={{ root: classes.workTitleButton }}
              skipTracking
            >
              {/* TODO: Move this logic to EntityName */}
              {titles?.map(
                ({ title, translation, composers, entityType: titleEntityType, entityId, isCustomTitle }, index) => (
                  <Typography key={index} variant="p" strikethrough={isCancelled}>
                    <Typography variant="span" weight="bold" size={14}>
                      <EntityName
                        name={title}
                        entityType={titleEntityType}
                        entity={{ id: entityId }}
                        // ENTITY_TYPE.PRODUCTION is covered as part of ProductionSlug using ImpressionTracker
                        trackingData={titleEntityType === ENTITY_TYPE.PRODUCTION ? undefined : trackingData}
                        isRaw={!isCustomTitle}
                      />
                    </Typography>
                    {translation && (
                      <Typography variant="span" size={14} color="secondary">
                        {' '}
                        ({translation})
                      </Typography>
                    )}
                    <Typography variant="span" size={14} color="secondary" italic>
                      ,{' '}
                      <SeparatorList
                        data={composers?.map(props => (
                          <EntityName key={props?.entity?.id} {...props} trackingData={trackingData} />
                        ))}
                      />
                    </Typography>
                  </Typography>
                ),
              )}
            </LinkButton>
            <SeparatorList data={producerNames} separator={SEPARATOR_TYPES.FORWARD_SLASH} />
            {upcoming && (conductorNames?.length > 0 || directorNames?.length > 0) && (
              <div>
                {conductorNames?.length > 0 && (
                  <Typography className={classes.primaryCrew} color="secondary">
                    C: <SeparatorList data={conductorNames} separator={SEPARATOR_TYPES.FORWARD_SLASH} />
                  </Typography>
                )}
                {directorNames?.length > 0 && (
                  <Typography className={classes.primaryCrew} color="secondary">
                    D: <SeparatorList data={directorNames} separator={SEPARATOR_TYPES.FORWARD_SLASH} />
                  </Typography>
                )}
              </div>
            )}
            <div className={classes.entityRoles}>
              {roles && (
                <Typography variant="p">
                  <RedMaskIcon entity={entity} entityType={entityType} data={data} />
                  <Typography variant="span" color="secondary">
                    {roles}
                  </Typography>
                  {isCover && (
                    <Typography color="secondary" italic size="12">
                      ({t(`${TP}.FN_COVER`)})
                    </Typography>
                  )}
                </Typography>
              )}
              {!hideLinks && (
                <Typography variant="span" color="secondary" className={classes.quickViewAction}>
                  {data?.productionWorks?.length > 1
                    ? t(`${TP}.FN_VIEW_PROGRAM_CAST_CREW`)
                    : t(`${TP}.FN_VIEW_CAST_CREW`)}{' '}
                  <SpriteIcon icon="chevron_right" className={classes.quickViewAction__icon} />
                </Typography>
              )}
            </div>
          </>
        </ContentWithSeason>
        {upcoming && (
          <div className={classes.dateList}>
            <PerformanceDatesInfo
              data={data}
              linkProps={linkProps}
              onClick={navigateToLinkProps}
              openQuickPreview={onClick}
              hideLinks={hideLinks}
              trackingData={trackingData}
            />
          </div>
        )}
      </ImpressionTracker>
    </div>
  );
};

export default memo(
  ProductionSlug,
  (oldProps, newProps) =>
    oldProps?.data?.id === newProps?.data?.id && oldProps?.data?.dates?.length === newProps?.data?.dates?.length,
);
