import React, { useCallback } from 'react';

import AutoCompleteDropdown from 'components/uiLibrary/FormInputs/BaseComponents/AutoCompleteDropdown';

import globalQueries from 'containers/Globals/queries';
import prodQueries from 'containers/Productions/queries';

import { useAppliedCityFilter } from 'utils/globals/ssrQueries/hooks';

import { TP, AGGREGATION_TYPES } from 'constants/index';

import { useTranslation } from 'src/i18n';

const CityDropdown = ({
  className,
  value,
  onChange,
  aggregationOn,
  aggregationFilters,
  disablePortal = false,
  searchQueryFilters = {},
  onBlur,
  label = `${TP}.m_CITY`,
  isDisabled = false,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  const aggregationQuery = useCallback(
    props =>
      prodQueries.getAggregatedEntities({
        ...props,
        aggregationOn,
        aggregationType: AGGREGATION_TYPES.CITY,
        filters: aggregationFilters,
      }),
    [aggregationFilters, aggregationOn],
  );

  const city = useAppliedCityFilter(value);

  return (
    <AutoCompleteDropdown
      className={className}
      label={t(label)}
      value={city}
      onChange={onChange}
      onBlur={onBlur}
      searchQueryFilters={searchQueryFilters}
      searchQuery={globalQueries.getCities}
      {...(aggregationOn && { aggregationQuery })}
      disablePortal={disablePortal}
      isDisabled={isDisabled}
    />
  );
};

export default CityDropdown;
