import React, { Fragment, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import useTracking from 'components/Globals/Analytics';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import LinkButton, { TertiaryButton } from 'components/uiLibrary/LinkButton';
import Loader from 'components/uiLibrary/Loader';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import { useTranslation } from 'src/i18n';
import usePageContext from 'utils/hooks/usePageContext';
import FilterFacets, { FILTER_LAYOUT } from 'components/Filters/Display/FilterFacets';
import SwitchV1 from 'components/uiLibrary/Switch/SwitchV1';

import {
  TP,
  PREMIUM_NUDGE_VIEW_TYPES,
  BASE_PAGE_ROUTES,
  PREMIUM_NUDGE_POSITION,
  POPUP_TYPES,
  THEMES,
} from 'constants/index';

import PropsDrillProvider from 'utils/hooks/usePropsDrillProvider';
import { TRACK_EVENTS, VIEW_ALL_SELECTED_PROFESSION_ARTISTS } from 'utils/tracking';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import { COMPONENTS, GOOGLE_OLD_TRACKING_SERVICES, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import { useSubscription } from 'utils/hooks/useSubscription';
import { SUBSCRIPTIONS_STATUSES } from 'containers/UserContainers/AccountSettingsContainer/constants';
import classes from './SectionBlock.module.scss';

import PremiumPlanNudge from '../Layout/PremiumPlanNudge';
import EmptyPlaceholder from '../Layout/EmptyPlaceholder';

const getFilterTrackingData = seeAllTrackingData => {
  if (!seeAllTrackingData) {
    return { trackingData: seeAllTrackingData, buttonTrackingData: seeAllTrackingData };
  }
  if (seeAllTrackingData.component === COMPONENTS.SEE_ALL_CTA) {
    return {
      trackingData: {
        ...seeAllTrackingData,
        component: COMPONENTS.FILTERS,
      },
      buttonTrackingData: {
        ...seeAllTrackingData,
        component: COMPONENTS.FILTER_CTA,
      },
    };
  }
  if (seeAllTrackingData.subComponent === SUB_COMPONENTS.SEE_ALL_CTA) {
    return {
      trackingData: {
        ...seeAllTrackingData,
        subComponent: SUB_COMPONENTS.FILTERS,
      },
      buttonTrackingData: {
        ...seeAllTrackingData,
        subComponent: SUB_COMPONENTS.FILTER_CTA,
      },
    };
  }

  if (seeAllTrackingData.subComponent) {
    return {
      trackingData: {
        ...seeAllTrackingData,
        subComponent: `${seeAllTrackingData.subComponent} / ${SUB_COMPONENTS.FILTERS}`,
      },
      buttonTrackingData: {
        ...seeAllTrackingData,
        subComponent: `${seeAllTrackingData.subComponent} / ${SUB_COMPONENTS.FILTER_CTA}`,
      },
    };
  }

  return { trackingData: seeAllTrackingData, buttonTrackingData: seeAllTrackingData };
};

export const SectionHeading = ({
  title,
  count,
  styles,
  linkProps,
  theme,
  isMobile,
  trackingData,
  disabledLink = false,
}) => (
  <LinkButton
    variant="text"
    isLink={linkProps}
    skipTracking={!linkProps}
    disabled={disabledLink}
    scroll
    {...(linkProps && {
      rightIcon: (
        <SpriteIcon
          className={classnames(classes.linkIcon, { [classes.linkIconWithoutCount]: !count })}
          icon="chevron_right"
          size={16}
        />
      ),
      ...linkProps,
    })}
    styles={{
      root: classnames(classes.title, {
        [classes.title__isLink]: !!linkProps,
        [classes.title__isLink_dark]: theme === THEMES.DARK && isMobile,
      }),
    }}
    disableUnderline
    title={title}
    trackingData={trackingData}
  >
    <Typography
      variant="h2"
      className={classnames({
        [styles?.title]: !!styles?.title,
      })}
      size={16}
      weight="bold"
      inline
      {...(theme === THEMES.DARK && isMobile && { color: 'white' })}
    >
      {title}
    </Typography>
    {count > 0 && (
      <Typography
        color="secondary"
        className={classes.count}
        size="12"
        weight="medium"
        inline
      >{` ${count}`}</Typography>
    )}
  </LinkButton>
);

function extractInnerText(element) {
  if (typeof element === 'string') {
    // If it's a string, it's the inner text, return it
    return element;
  }

  // If it's not a string, it's a React element object
  if (Array.isArray(element?.props?.children)) {
    // If children is an array, iterate through each child element
    return element.props.children.map(child => extractInnerText(child)).join(' ');
  }
  return '';
}

const SeeAll = ({ linkProps, showIcon, suffixTitle, title, entityName, trackingData, theme, isMobile }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();
  const { baseRoute, entity = {} } = usePageContext();

  const handleOnClick = useCallback(() => {
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        id: VIEW_ALL_SELECTED_PROFESSION_ARTISTS,
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  }, [track]);

  const icon = <SpriteIcon icon="navigate_next" />;

  return (
    <LinkButton
      variant="text"
      isLink={!linkProps?.onClick}
      scroll
      {...((showIcon || isMobile) && { rightIcon: icon })}
      {...linkProps}
      title={
        linkProps?.linkTitle ||
        t('VIEW_ALL_TITLE', { ns: 'NS_ENTITY_STUB_PAGE', title: extractInnerText(title), entityName })
      }
      styles={{
        root: classnames(classes.seeAllLink, {
          [classes.seeAllLink_dark]: theme === THEMES.DARK && isMobile,
        }),
        icon: classes.seeAllArrow,
      }}
      {...((baseRoute === BASE_PAGE_ROUTES.ARTISTS || !entity?.id) && { onClick: handleOnClick })}
      trackingData={trackingData}
    >
      {suffixTitle && title
        ? linkProps?.title || t('VIEW_ALL_TITLE', { ns: 'NS_ENTITY_STUB_PAGE', title, entityName })
        : t(`${TP}.VIEW_ALL`)}
    </LinkButton>
  );
};

export const Add = ({ addLinkProps, trackingData = {} }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();

  if (!addLinkProps || !isDesktop) {
    return null;
  }

  return (
    <TertiaryButton
      size={isDesktop ? 'medium' : 'small'}
      variant="text"
      styles={{ root: classes.addBtn }}
      leftIcon={<SpriteIcon icon="add_circle" size={14} className={classes.addBtn__icon} />}
      {...addLinkProps}
      isLink
      scroll
      trackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.ADD }}
    >
      {t('CL_CTA_ADD')}
    </TertiaryButton>
  );
};

export const Edit = ({ linkProps, isCustomization, trackingData = {} }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();

  if (!linkProps) {
    return null;
  }

  return (
    <TertiaryButton
      variant="text"
      size={!isDesktop ? 'small' : 'medium'}
      leftIcon={<SpriteIcon icon="edit" size={14} className={classes.editBtn__icon} />}
      styles={{
        root: classes.editBtn,
      }}
      isLink
      {...linkProps}
      scroll
      trackingData={{ ...trackingData, subComponent: isCustomization ? COMPONENTS.CUSTOMIZE_CTA : COMPONENTS.EDIT_CTA }}
    >
      {t(`${TP}.${isCustomization ? 'FN_CUSTOMISE' : 'm_EDIT'}`)}
    </TertiaryButton>
  );
};

const ShowHideToggle = ({
  onToggle,
  value,
  theme,
  isDisabled = false,
  popupSource,
  specialPermission,
  popupType = POPUP_TYPES.UPGRADE,
  trackingData = {},
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { setSubscriptionNudgesPopup } = useNotificationPopup();
  const { isMobile } = useDeviceTypeLayouts();
  const subscription = useSubscription();
  const isChurned = subscription?.status === SUBSCRIPTIONS_STATUSES.cancelled;

  const confirmSpecialPermissionWithSource = (specialPerm, func, popupSrc) =>
    specialPerm
      ? func
      : () =>
          setSubscriptionNudgesPopup({
            isOpen: true,
            popupType: isChurned ? POPUP_TYPES.CHURNED : popupType,
            source: popupSrc,
            isCompare: false,
          });

  return (
    <PropsDrillProvider confirmSpecialPermissionWithSource={confirmSpecialPermissionWithSource}>
      <SwitchV1
        leftLabel={t('CL_CTA_HIDE')}
        rightLabel={t('CL_CTA_SHOW')}
        isShow={value}
        isDisabled={isDisabled}
        onChange={onToggle}
        source={popupSource}
        specialPermission={specialPermission}
        trackingData={trackingData}
        styles={{
          ...(value ? { leftLabel: classes.switchLeftLabel } : { rightLabel: classes.switchRightLabel }),
          slider: value ? classes.toggleSlider_active : classes.toggleSlider_nonActive,
          ...(theme === THEMES.DARK && isMobile && { root: classes.switchContainerDark }),
        }}
      />
    </PropsDrillProvider>
  );
};

const SectionBlock = ({
  title,
  description,
  filterProps,
  linkProps,
  enableSeeAll = true,
  enableViewAllButton = false,
  children,
  count,
  className,
  styles,
  withTitle = true,
  titleChildren,
  isLoading = false,
  seeAllTrackingData,
  editMode = {},
  showHideProps = {},
  premiumSection,
  hideSectionDivider,
  theme,
  isSectionOnRight = false,
  addMore = {},
  disabledLink = false,
}) => {
  const { isMobile } = useDeviceTypeLayouts();
  const { permissions, entity } = usePageContext();
  const hasMultiEditActions = showHideProps?.enabled && editMode?.enabled;
  const hasActions = filterProps?.types?.length > 0 || showHideProps?.enabled || enableSeeAll || editMode?.enabled;
  const showViewAllButton = useMemo(() => {
    if (enableSeeAll && linkProps) {
      return !editMode?.enabled || editMode?.hasContent;
    }
    return false;
  }, [editMode, enableSeeAll, linkProps]);

  if (withTitle && !title) {
    return null;
  }

  const showDottedBorder =
    [
      PREMIUM_NUDGE_VIEW_TYPES.DEFAULT,
      PREMIUM_NUDGE_VIEW_TYPES.INLINE,
      PREMIUM_NUDGE_VIEW_TYPES.INLINE_WITH_BORDER,
    ].includes(premiumSection?.type) && premiumSection?.enabled;
  const Gradient = showDottedBorder ? 'div' : Fragment;

  return (
    <>
      {premiumSection?.enabled && premiumSection?.placement === PREMIUM_NUDGE_POSITION.TOP && (
        <PremiumPlanNudge {...premiumSection} nudgeSourceType={premiumSection?.nudgeSourceType} />
      )}
      <div
        className={classnames(classes.sectionBlock, {
          [classes.sectionBlock__dotted]: !!showDottedBorder,
          [classes.sectionBlock__dotted_isSectionOnRight]: !!showDottedBorder && !!isSectionOnRight,
          [className]: !!className,
          [styles?.root]: !!styles?.root,
        })}
      >
        <Gradient
          {...(showDottedBorder && {
            className: classnames(classes.sectionBlock__dotted_background, {
              [styles?.gradient]: styles?.gradient,
            }),
          })}
        />
        {withTitle && (
          <div
            className={classnames(classes.header, {
              [classes.header__hasMultiEditActions]: hasMultiEditActions,
              [classes.header__hideSectionDivider]:
                !permissions?.isTargetMyProfile || editMode?.hasContent || hideSectionDivider,
              [classes.header__hasDescription]: !!description,
              [classes.header__isNonPremiumSection]: !!showDottedBorder,
              [styles?.header]: !!styles?.header,
            })}
          >
            <div
              className={classnames(classes.leftBoxIcon, {
                [classes.leftBoxIcon_dark]: theme === THEMES.DARK && isMobile,
                [styles?.leftBoxIcon]: !!styles?.leftBoxIcon,
              })}
            >
              <SectionHeading
                title={title}
                linkProps={linkProps}
                count={count}
                styles={styles}
                theme={theme}
                isMobile={isMobile}
                disabledLink={disabledLink}
                trackingData={seeAllTrackingData}
              />
              {titleChildren}
            </div>
            {hasActions && (
              <div
                className={classnames(classes.header__actions, {
                  [classes.header__actions_hasMultiEditActions]: hasMultiEditActions,
                })}
              >
                {filterProps?.types?.length > 0 && !editMode?.enabled && (
                  <div className={classes.header__actions_item}>
                    <FilterFacets
                      {...filterProps}
                      layout={FILTER_LAYOUT.BUTTON}
                      {...getFilterTrackingData(seeAllTrackingData)}
                    />
                  </div>
                )}
                {showViewAllButton && (
                  <div className={classes.header__actions_item}>
                    <SeeAll
                      linkProps={linkProps}
                      title={title}
                      theme={theme}
                      trackingData={seeAllTrackingData}
                      isMobile={isMobile}
                    />
                  </div>
                )}
                {showHideProps?.enabled && <ShowHideToggle {...showHideProps} theme={theme} />}
                {editMode?.enabled && (
                  <>
                    <Edit {...editMode} />
                    <Add {...editMode} />
                  </>
                )}
              </div>
            )}
          </div>
        )}
        {description && (
          <Typography size="12" color="secondary" className={classes.description}>
            {description}
          </Typography>
        )}
        {premiumSection?.enabled && premiumSection?.placement !== PREMIUM_NUDGE_POSITION.TOP && (
          <PremiumPlanNudge {...premiumSection} nudgeSourceType={premiumSection?.nudgeSourceType} />
        )}
        {isLoading ? <Loader /> : children || null}
        {enableViewAllButton && (
          <div className={classes.seeAllButton}>
            <SeeAll
              entityName={entity?.name}
              linkProps={linkProps}
              title={title}
              suffixTitle
              showIcon
              trackingData={seeAllTrackingData}
            />
          </div>
        )}
        {addMore?.enabled && (
          <EmptyPlaceholder sections={addMore?.emptySections} styles={{ root: classes.placeholderAtEnd }} />
        )}
      </div>
    </>
  );
};

export default SectionBlock;
