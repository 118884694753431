/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo, useCallback, useRef } from 'react';
import classnames from 'classnames';
import omit from 'lodash/omit';
import Overflow from 'rc-overflow';

import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Popover from 'components/uiLibrary/Popover';
import Modal from 'components/uiLibrary/Modal';
import LinkButton from 'components/uiLibrary/LinkButton';

import useScrollBlocker from 'utils/hooks/useScrollBlocker';
import usePageContext from 'utils/hooks/usePageContext';
import useArchiveLoginModal from 'utils/hooks/useArchiveLoginModal';
import { useQuery } from 'utils/react-query';
import useTracking from 'components/Globals/Analytics';
import { SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import { FILTER_AGGREGATION_MAP, FILTER_LABELS, FILTER_SLUG_TYPE, FILTER_TYPES } from 'constants/filters';
import {
  TP,
  ENTITY_AGGREGATION_TYPE_MAP,
  WORK_CREATOR_PROFESSION_TYPES,
  ENTITY_META_DETA_TYPES,
  SEASON_AGGREGATION_TYPES,
  ORGANIZATION_TYPE_IDS,
  ENTITY_TYPE,
  ENTITY_MAIN_TABS,
  ENTITY_DETAILS_TAB,
} from 'constants/index';

import queries from 'containers/Productions/queries';
import { useTranslation } from 'src/i18n';
import { getEntityIdWithPrefix } from 'utils/globals';

import AggregationFilter from '../AggregationFilter';
import classes from './FilterFacets.module.scss';

export const LAYOUT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  BUTTON: 'button',
};

const FilterChip = ({
  type,
  count,
  appliedCount,
  onClick,
  active,
  disable,
  inline,
  isExpanded,
  upcoming,
  hideChevron,
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const ref = useRef(null);
  const { getFilterUsageState } = usePageContext();
  const filterState = useMemo(() => getFilterUsageState({ type, upcoming }), [getFilterUsageState, type, upcoming]);

  return (
    <div
      className={classnames(classes.chip, { [classes.active]: active, [classes.disabled]: !!disable })}
      ref={ref}
      {...(!disable && { onClick: () => onClick({ anchor: ref?.current, type }) })}
    >
      <Typography
        variant="span"
        size={12}
        className={classes.chip__label}
        weight={isExpanded ? 'medium' : 'regular'}
        color={disable ? 'tertiary' : 'primary'}
        truncate
      >
        {t(FILTER_LABELS[type])}
        {!disable && typeof count === 'number' && ` (${count})`}
      </Typography>
      {!disable && appliedCount > 0 && (
        <Typography variant="span" size={12} className={classes.chip__applied} color="white">
          {appliedCount}
        </Typography>
      )}
      {filterState.isAdvancedFilter && (
        <SpriteIcon className={classes.chip__lock} icon={filterState?.isRestricted || disable ? 'lock' : 'lock_open'} />
      )}
      {!hideChevron && <SpriteIcon className={classes.chip__icon} icon={inline ? 'expand_more' : 'chevron_right'} />}
    </div>
  );
};

const ExtraFilters = ({ count, onClick }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  return (
    <div className={classes.chip} onClick={() => onClick({})}>
      {t(`${TP}.FN_PLUS_X_MORE_NO_BRACKET`, { number: count })}
      <SpriteIcon className={classes.chip_icon} icon="expand_more" />
    </div>
  );
};

const OverflowItemWrapper = ({ inline, children }) => {
  if (inline) {
    return <div className={classes.overflowItemWrapper}>{children}</div>;
  }

  return children;
};

const RenderFacets = ({
  types,
  stats,
  expandedFilter,
  onExpandFilter,
  scrollable = false,
  disable = false,
  inline = false,
  upcoming = false,
  disabledTypes,
  hideChevron = false,
  hideFilterCounts = false,
}) => {
  const { filters, entityType } = usePageContext();

  const orderedTypes = useMemo(() => {
    let filterTypes = types;
    filterTypes = filterTypes.filter(type => !disabledTypes.includes(type));

    if ([ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType)) {
      filterTypes = filterTypes.filter(type => type !== FILTER_TYPES.SEASON);
    }

    const typesWithInfo = filterTypes?.reduce((acc, type) => {
      const slugType = FILTER_SLUG_TYPE[type];
      const appliedCount = filters?.[slugType]?.length || 0;

      acc.push({
        type,
        appliedCount,
        count: hideFilterCounts ? null : stats?.[type],
        active: expandedFilter === type,
        disable: disabledTypes?.includes(type),
      });

      return acc;
    }, []);

    if (inline) {
      return typesWithInfo.sort((filterA, filterB) => {
        if (filterA?.appliedCount > 0 && filterB?.appliedCount === 0) {
          return -1;
        }

        if (filterB?.appliedCount > 0 && filterA?.appliedCount === 0) {
          return 1;
        }

        return 0;
      });
    }

    return typesWithInfo;
  }, [types, entityType, inline, filters, stats, expandedFilter, disabledTypes, hideFilterCounts]);

  return (
    <Overflow
      className={classnames(classes.typeList, { [classes.inline]: inline })}
      data={orderedTypes}
      renderItem={item => (
        <OverflowItemWrapper inline={inline}>
          <FilterChip
            key={item?.type}
            type={item?.type}
            active={item?.active}
            count={item?.count}
            appliedCount={item?.appliedCount}
            onClick={onExpandFilter}
            disable={disable || item?.disable}
            inline={inline}
            upcoming={upcoming}
            hideChevron={hideChevron}
          />
        </OverflowItemWrapper>
      )}
      renderRest={items => <ExtraFilters count={items?.length} onClick={onExpandFilter} />}
      maxCount={inline && !scrollable ? 'responsive' : null}
    />
  );
};

const FilterModalHeader = ({ mainPath, upcoming, hasFiltersApplied, onResetFilters, onClose }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const title = useMemo(() => {
    let baseTitle = t(`${TP}.FILTERS`);

    if (mainPath === ENTITY_MAIN_TABS.PERFORMANCES) {
      baseTitle += upcoming ? ` (${t(`${TP}.FN_UPCOMING`)})` : ` (${t(`${TP}.FN_PAST`)})`;
    }

    return baseTitle;
  }, [t, mainPath, upcoming]);

  return (
    <div className={classes.header}>
      <div className={classes.header__label}>
        <div className={classes.header__icon}>
          <SpriteIcon icon="tune" />
        </div>
        <Typography weight="medium">{title}</Typography>
        {hasFiltersApplied && <SpriteIcon icon="replay-bold" onClick={onResetFilters} />}
      </div>
      <SpriteIcon className={classes.closeIcon} icon="close" onClick={onClose} />
    </div>
  );
};

const FilterFacets = ({
  types = [],
  mainPath,
  commonFilterParams = {},
  disable = false,
  disabledTypes = [],
  clearExcludedTypes = [],
  upcoming = false,
  layout = LAYOUT.VERTICAL,
  scrollable = false,
  trackingData = {},
  buttonTrackingData = {},
}) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const {
    entityType,
    entityId,
    entity,
    filters,
    filterParams,
    applyFilters,
    resetFilters,
    navigate,
    filterTypeUsage,
    mainPath: currentMainPath,
  } = usePageContext();
  const track = useTracking();
  const [filterAnchor, setFilterAnchor] = useState(null);
  const [expandedFilter, setExpandedFilter] = useState(() => {
    if (filterTypeUsage?.limitExceeded) {
      return filterTypeUsage?.focusFilterType || types[0];
    }

    return null;
  });
  const openArchiveAccessModal = useArchiveLoginModal();
  useScrollBlocker(expandedFilter);

  const baseAppliedFilters = useMemo(() => {
    const defaultFilters = {
      ...(entityId && {
        ...([ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType)
          ? {
              ctx_entity: getEntityIdWithPrefix({ entityType, entityId }),
            }
          : { [ENTITY_AGGREGATION_TYPE_MAP[entityType]]: entityId }),
      }),
      ...(types?.includes(FILTER_TYPES.SEASON) && {
        aggregation_season_type:
          entity?.metadata?.find(({ name }) => name === ENTITY_META_DETA_TYPES.SEASON)?.value ||
          SEASON_AGGREGATION_TYPES.CALENDAR.value,
      }),
      ...(expandedFilter === FILTER_TYPES.CREATOR && {
        work_creator_profession: WORK_CREATOR_PROFESSION_TYPES.COMPOSER,
      }),
    };

    let virtualFilter = {};

    if (entityType === ENTITY_TYPE.ARTIST) {
      virtualFilter = {
        include_virtual_contributors: true,
      };
    }

    return {
      ...virtualFilter,
      ...defaultFilters,
      ...filterParams,
      ...commonFilterParams,
    };
  }, [entityId, entityType, types, entity?.metadata, filterParams, commonFilterParams, expandedFilter]);

  const appliedFilters = useMemo(
    () => ({
      ...baseAppliedFilters,
      ...([FILTER_TYPES.COMPANY, FILTER_TYPES.FESTIVAL, FILTER_TYPES.CHORUS, FILTER_TYPES.ORCHESTRA].includes(
        expandedFilter,
      ) && {
        has_org_type_id: ORGANIZATION_TYPE_IDS[expandedFilter],
        aggregation_type: FILTER_AGGREGATION_MAP[FILTER_TYPES.ORGANIZATION],
      }),
    }),
    [expandedFilter, baseAppliedFilters],
  );

  const isStubOverviewTab =
    [ENTITY_TYPE.ARTIST, ENTITY_TYPE.ORGANIZATION].includes(entityType) &&
    !currentMainPath &&
    [LAYOUT.HORIZONTAL, LAYOUT.BUTTON].includes(layout);

  const { data: stats } = useQuery(
    queries.getFilterCounts({
      types,
      filters: {
        ...baseAppliedFilters,
        ...(types?.includes(FILTER_TYPES.CREATOR) && {
          work_creator_profession: WORK_CREATOR_PROFESSION_TYPES.COMPOSER,
        }),
      },
      extraQueryKeys: [types],
      queryConfig: {
        enabled: !disable && types?.length > 0 && (!isStubOverviewTab || !!expandedFilter),
        keepPreviousData: true,
      },
    }),
  );

  const filterSlugsToIgnore = useMemo(() => clearExcludedTypes?.map(type => FILTER_SLUG_TYPE[type]), [
    clearExcludedTypes,
  ]);

  const hasFiltersApplied = useMemo(() => Object.keys(omit(filterParams, filterSlugsToIgnore))?.length > 0, [
    filterParams,
    filterSlugsToIgnore,
  ]);

  const onResetFilters = useCallback(
    e => {
      if (e) {
        e.stopPropagation();
        e.preventDefault();
      }

      resetFilters({ entityType, entity }, filterSlugsToIgnore);

      navigate.scrollTo();
    },
    [navigate, resetFilters, entityType, entity, filterSlugsToIgnore],
  );

  const onExpandFilter = useCallback(
    ({ type, anchor, trackingData: filterTrackingData }) => {
      setFilterAnchor(anchor);
      setExpandedFilter(type || types[0]);
      track.click({
        ...(filterTrackingData || trackingData),
        meta: { filterTab: type || types[0] },
      });
    },
    [types, trackingData?.section],
  );

  const onCloseFilter = useCallback(() => {
    setFilterAnchor(null);
    setExpandedFilter(null);
  }, []);

  const onApplyFilters = useCallback(
    (slugType, value) => {
      let filterSubPath;

      if (
        mainPath === ENTITY_MAIN_TABS.PERFORMANCES &&
        !upcoming &&
        !filters[FILTER_SLUG_TYPE[FILTER_TYPES.SEASON]]?.length
      ) {
        filterSubPath = ENTITY_DETAILS_TAB.PAST;
      }

      const data = {
        entityType,
        entity,
        mainPath,
        subPath: filterSubPath,
        filters: {
          ...omit(filters, slugType),
          ...(value?.length > 0 ? { [slugType]: value } : {}),
        },
      };

      applyFilters(data);

      navigate.scrollTo();
      track.click({
        ...trackingData,
        subComponent: trackingData?.subComponent
          ? `${trackingData.subComponent} / ${SUB_COMPONENTS.SUBMIT_CTA}`
          : SUB_COMPONENTS.SUBMIT_CTA,
        meta: { filters: data.filters },
      });
    },
    [mainPath, upcoming, filters, entityType, entity, applyFilters, navigate],
  );

  const onUpdateFilters = useCallback(
    selectedOptions => {
      onCloseFilter();
      onApplyFilters(FILTER_SLUG_TYPE[expandedFilter], selectedOptions);
    },
    [expandedFilter, onApplyFilters, onCloseFilter],
  );

  const onAccessArchives = useCallback(() => {
    onCloseFilter();
    openArchiveAccessModal();
  }, [onCloseFilter, openArchiveAccessModal]);

  return (
    <>
      {layout === LAYOUT.BUTTON ? (
        <LinkButton
          variant="text"
          leftIcon={<SpriteIcon icon="tune" size={17} />}
          onClick={() => onExpandFilter({ type: types[0], trackingData: buttonTrackingData })}
          // Tracked in onClick
          skipTracking
        >
          {t(`${TP}.FILTERS`)}
        </LinkButton>
      ) : (
        <div className={classnames(classes.root, { [classes.inline]: layout === LAYOUT.HORIZONTAL })}>
          <div
            className={classes.header}
            {...(!disable && layout === LAYOUT.HORIZONTAL && { onClick: () => onExpandFilter({ type: types[0] }) })}
          >
            <div className={classes.header__label}>
              <Typography weight="medium" className={classes.header__label_text}>
                {t(`${TP}.FILTERS`)}
              </Typography>
              {hasFiltersApplied && <SpriteIcon icon="replay-bold" onClick={onResetFilters} />}
            </div>
            <div className={classes.header__icon}>
              <SpriteIcon icon="tune" />
            </div>
          </div>

          <RenderFacets
            types={types}
            stats={stats}
            onExpandFilter={onExpandFilter}
            inline={layout === LAYOUT.HORIZONTAL}
            scrollable={scrollable}
            disable={disable}
            expandedFilter={expandedFilter}
            disabledTypes={disabledTypes}
            upcoming={upcoming}
            hideFilterCounts={isStubOverviewTab}
            trackingData={trackingData}
          />

          {expandedFilter && layout === LAYOUT.VERTICAL && (
            <Popover
              anchorEl={filterAnchor}
              container={filterAnchor}
              onClose={onCloseFilter}
              styles={{
                root: classes.filterPopover,
              }}
              anchorOrigin={{
                horizontal: 'right',
              }}
              transformOrigin={{
                horizontal: 'left',
              }}
              transition
            >
              <AggregationFilter
                type={expandedFilter}
                total={stats?.[expandedFilter]}
                commonFilterParams={appliedFilters}
                onUpdateFilters={onUpdateFilters}
                onAccessArchives={onAccessArchives}
                upcoming={upcoming}
                trackingData={trackingData}
              />
            </Popover>
          )}
        </div>
      )}

      {expandedFilter && layout !== LAYOUT.VERTICAL && (
        <Modal
          onClose={onCloseFilter}
          styles={{
            modalContainer: classes.filterModal,
            drawerPaper: classes.filterDrawerPaper,
            content: classes.filterDrawerContent,
          }}
          header={
            <FilterModalHeader
              mainPath={mainPath}
              upcoming={upcoming}
              hasFiltersApplied={hasFiltersApplied}
              onResetFilters={onResetFilters}
              onClose={onCloseFilter}
              trackingData={trackingData}
            />
          }
          isOpen
          allowMobileDrawer
        >
          <div className={classes.filterModal__content}>
            <div className={classes.filterModal__content_facets}>
              <RenderFacets
                types={types}
                stats={stats}
                onExpandFilter={onExpandFilter}
                disable={disable}
                expandedFilter={expandedFilter}
                disabledTypes={disabledTypes}
                upcoming={upcoming}
                hideChevron
                trackingData={trackingData}
              />
            </div>
            <div className={classes.filterModal__content_options}>
              <AggregationFilter
                type={expandedFilter}
                total={stats?.[expandedFilter]}
                commonFilterParams={appliedFilters}
                onUpdateFilters={onUpdateFilters}
                onAccessArchives={onAccessArchives}
                asPopover={false}
                inline={layout !== LAYOUT.VERTICAL}
                upcoming={upcoming}
                trackingData={trackingData}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default FilterFacets;
